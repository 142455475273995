<template>
    <transition name="slide">
      <CCard>
        <CCardBody>
          <CButton color="primary" @click="create()"
            ><CIcon name="cil-plus" /> Create New Company</CButton
          >
          <br />
          <br />
          <br />  
          <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
  
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="50"
            hover
            pagination
          >

            <template #name="{ item }">
              <td style="width: 20%">
                <strong>{{ item.name }}</strong>
              </td>
            </template>

            <template #country="{ item }">
              <td style="width: 20%">
                <strong>{{ item.country }}</strong>
              </td>
            </template>

            <template #companyEmail="{ item }">
              <td style="width: 20%">
                <strong>{{ item.companyEmail }}</strong>
              </td>
            </template>

            <template #EDIT="{ item }">
              <td>
                <CButton color="primary" @click="edit(item.id)"
                  ><CIcon name="cil-pencil" /> Edit</CButton
                >
              </td>
            </template>
            <template #DELETE="{ item }">
              <td>
                <CButton
                  color="danger"
                  @click="
                    warningModal = true;
                    company = item;
                  "
                  ><CIcon name="cil-trash" /> Delete</CButton
                >
              </td>
            </template>
          </CDataTable>
          <CModal
            v-model="company.id"
            title="Are you sure to delete this company?"
            color="danger"
            :show.sync="warningModal"
            @update:show="closeModal"
          >
            <CRow>
              <CCol col="6">
                <CInput label="Name" v-model="company.name" disabled />
              </CCol>
            </CRow>
          </CModal>
        </CCardBody>
      </CCard>
    </transition>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "Companies",
  
    data: () => {
      return {
        // use uuuid
        emptyGuid: "00000000-0000-0000-0000-000000000000",
        fields: ["name", "country","companyEmail", "vatNumber", "EDIT", "DELETE"],
  
        warningModal: false,
        company: [],
  
        // Alert
        alertType: "danger",
        message: null,
  
        // List
        items: [],
      };
    },
    computed: {},
    methods: {
      getBadge(status) {
        switch (status) {
          case true:
            return "success";
          case false:
            return "secondary";
          default:
            return "primary";
        }
      },
  
      closeModal(status, evt, accept) {
        if (accept) {
          this.delete(this.company.id);
        }
      },
  
      edit(id) {
        this.$router.push({ path: `/companies/create/${id}` });
      },
      delete(id) {
        let self = this;
        axios
          .delete(this.$apiAdress + "/v1/Company/" + id)
  
          .then(function () {
                      self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
            self.message = "Successfully deleted company.";
            self.getList();
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
      create() {
        this.$router.push({ path: "/companies/create/" + this.emptyGuid });
      },
  
      getList() {
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Company")
          .then(function (response) {
            self.items = response.data;
          })
          .catch(function (error) {
                      self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
            self.message = error;
          });
      },
    },
    mounted: function () {
      this.getList();
    },
  };
  </script>
  
  <style scoped>
  .card-body >>> table > tbody > tr > td {
    cursor: pointer;
  }
  </style>
  